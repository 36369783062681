// images
import Chrome from "../images/chrome.png";
import Firefox from "../images/firefox.png";
import Edge from "../images/edge.png";

export const pageTexts = (t) => {
  return {
    browser: {
      title: {
        text: t("unsupported-browser.title"),
        colorClass: "colorDark",
      },
      text: {
        text: t("unsupported-browser.subtitle"),
        colorClass: "colorDark",
        textType: "paragraph",
        extraClass: "font17 lineHeight1_3"
      },
      separator: false,
    },
    browserList: [
      {
        name: t("unsupported-browser.chrome"),
        img: { src: Chrome, alt: t("unsupported-browser.chromeAlt") },
        href: "https://www.google.com/chrome/browser/desktop/index.html",
      },
      {
        name: t("unsupported-browser.firefox"),
        img: { src: Firefox, alt: t("unsupported-browser.firefoxAlt") },
        href: "https://www.mozilla.org/firefox/new/",
      },
      {
        name: t("unsupported-browser.edge"),
        img: { src: Edge, alt: t("unsupported-browser.edgeAlt") },
        href: "https://www.microsoft.com/windows/microsoft-edge",
      },
    ],
  };
};
