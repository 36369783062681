/**
 * Foursource page
 * https://foursource.com/unsupported-browser/
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/unsupported-browser";

// components
import Layout from "../components/layout";
import TitleSeparator from "../components/titleSeparator";

class unsupportedBrowserPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="unsupported-browser" whiteMenu={true}>
          <div className="pt-menu">
            <div className="paddingSidesBig pt-100 pb-200 pb-75 pt-75 text-center">
              <TitleSeparator info={this.text.browser}></TitleSeparator>
              <div className="paddingSidesBigger pt-20 row row-cols-3">
                {this.text.browserList.map((col, i) => (
                  <div key={i} className="col">
                    <a href={col.href}>
                      <img src={col.img.src} alt={col.img.alt} />
                      <div className="pt-10 font17">{col.name}</div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(unsupportedBrowserPage);
